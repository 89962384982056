import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
    }
  }

  handleSliderDotClick(slideIdx) {
    this.setState({
      currentSlide: slideIdx
    })

    if (this.interval) {
      clearInterval(this.interval)
      delete this.interval
      this.componentDidMount()
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const nextSlideIdx = (this.state.currentSlide + 1) % 4

      this.setState({
        currentSlide: nextSlideIdx
      })
    }, 5000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      delete this.interval
    }
  }

  render() {
    const jumbotronBackgroundUrl = (()=>{
      switch (this.state.currentSlide) {
        case 0:
          return "/static/images/photos/home.jpg"
        case 1:
          return "/static/images/photos/ptdck.jpg"
        case 2:
          return "/static/images/photos/cks.jpg"
        case 3:
          return "/static/images/photos/dus.jpg"
        default:
          return "/static/images/photos/home.jpg"
      }
    })()

    return <Template currentPage="home" lightnessTheme="dark">
      <Helmet>
        <title>Daya Selaras Group - Home</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: `url('${jumbotronBackgroundUrl}')`}}>
        <div className={styles.fence}>
          {
            this.state.currentSlide === 0 ? (
              <>
              <div className={styles.valueProposition}>
                We are a <span className={styles.overline}>SUSTAINABLE INDUSTRIAL HUB</span> linking the industrial loop from waste paper to corrugated carton packaging. We have a network of partners and/or affiliates in the waste paper, industrial paper, corrugated carton box and logistics sectors, therefore forming a circular value chain.
              </div>
              <Link to="/our-story" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
              </>
            ) : (
              this.state.currentSlide === 1 ? (
                <div className={styles.cksSlide}>
                  <img src="/static/images/ptdck-logo.png" className={styles.logo} />
                  <div className={styles.title}>
                    DAYA CIPTA KEMASINDO
                  </div>
                  <div className={styles.info}>
                    A leading integrated corrugated carton manufacturer in Indonesia. Our commitment is to provide quality corrugated recycled packaging solutions to our customers.
                  </div>
                  <Link to="/ptdck" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
                </div>
              ) : (
                this.state.currentSlide === 2 ? (
                  <div className={styles.cksSlide}>
                    {/* <img src="/static/images/cks-logo.png" className={styles.logo} /> */}
                    <div className={styles.title}>
                      CIPTA KELOLA SELARAS
                    </div>
                    <div className={styles.info}>
                      An industrial logistics company providing  value-added delivery and sustainable management in Indonesia.
                    </div>
                    <Link to="/cks" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
                  </div>
                ) : (
                  this.state.currentSlide === 3 ? (
                    <div className={styles.ptdckSlide}>
                      {/* <img src="/static/images/cks-logo.png" className={styles.logo} /> */}
                      <div className={styles.title}>
                        DAUR ULANG SELARAS
                      </div>
                      <div className={styles.info}>
                        A waste paper recycling management company that recovers and transforms waste paper into value by promoting a circular value chain.
                      </div>
                      <Link to="/dus" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
                    </div>
                  ) : ""
                )
              )
            )
          }
          <div className={styles.slider}>
            <div className={`${styles.sliderDot} ${this.state.currentSlide === 0 ? styles.currentSlide : styles.notCurrentSlide}`} onClick={this.handleSliderDotClick.bind(this, 0)}></div>
            <div className={`${styles.sliderDot} ${this.state.currentSlide === 1 ? styles.currentSlide : styles.notCurrentSlide}`} onClick={this.handleSliderDotClick.bind(this, 1)}></div>
            <div className={`${styles.sliderDot} ${this.state.currentSlide === 2 ? styles.currentSlide : styles.notCurrentSlide}`} onClick={this.handleSliderDotClick.bind(this, 2)}></div>
            <div className={`${styles.sliderDot} ${this.state.currentSlide === 3 ? styles.currentSlide : styles.notCurrentSlide}`} onClick={this.handleSliderDotClick.bind(this, 3)}></div>
          </div>
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
